import React from 'react';
import { Flex } from '@invitation-homes/styled-ui/lib/components/Common/Containers';
import { Button } from '@invitation-homes/styled-ui/lib/components/Button/Button';
import errorMessages from '../utils/errorMessages';
import styled from 'styled-components';
import ErrorHome from '../assets/error-home.svg';

import { MessageContainer, MessageHeader, MessageDescription } from '../components/CardCommon';

import Contact from '../components/Contact';

const CardContainer = styled.div`
  justify-content: center;
  background-color: white;
  flex-direction: column;
  width: 100%;
  display: flex;
  box-sizing: border-box;
`;

const StyledContactMessage = styled.div`
  margin-top: 20px;
`;

const ErrorImage = ({ type }) =>
  errorMessages[type] === 'ERR_SUSPICIOUS_USER' ? (
    <img src={ErrorHome} width="64px" height="64px" style={{ marginBottom: '8px' }} alt="" />
  ) : null;

const ErrorButton = ({ type }) =>
  errorMessages[type] === 'ERR_UNIT_NOT_AVAILABLE' ? (
    <Button
      color="primary"
      variant="contained"
      href={process.env.REACT_APP_PLS_URL}
      fontSize="10px"
      pt="14"
      pb="14"
      mt={15}
    >
      Search Our Homes
    </Button>
  ) : null;

const EXCLUDED_MESSAGES = ['_', 'ERR_SUSPICIOUS_USER', 'ERR_SHOWINGS_MULTIPLE_STATES', 'ERR_TOUR_GENERIC'];
const CustomerCareMessage = ({ type }) =>
  !EXCLUDED_MESSAGES.includes(errorMessages[type]) ? (
    <StyledContactMessage>
      <MessageDescription>Or, feel free to contact our Customer Care team at (888) 990-4684</MessageDescription>
    </StyledContactMessage>
  ) : null;

const ErrorMessageContent = ({ type, unitId }) => {
  const errorMessage = errorMessages[type] || errorMessages['_'];

  return (
    <>
      <ErrorImage type={type} />
      <MessageHeader>{errorMessage.title}</MessageHeader>
      <MessageDescription>{errorMessage.message}</MessageDescription>
      {errorMessage.showForm && <Contact unitId={unitId?.unit_id} />}
      <ErrorButton type={type} />
      <CustomerCareMessage type={type} />
    </>
  );
};

const DEFAULT_PADDING = { pt: 50, pb: 50, pl: 30, pr: 30 };

const ErrorMessage = ({ type = '_', unitId, padding = DEFAULT_PADDING }) => {
  const { pt, pb, pl, pr } = padding;

  return (
    <Flex direction="column" wrap="true" pt={pt} pb={pb} pl={pl} pr={pr}>
      <CardContainer>
        <MessageContainer>
          <ErrorMessageContent type={type} unitId={unitId} />
        </MessageContainer>
      </CardContainer>
    </Flex>
  );
};

export default ErrorMessage;
