import React, { Component, Fragment } from 'react';
import { assign } from 'lodash';
import moment from 'moment';

// Material UI
import Grid from '@material-ui/core/Grid';

//Styled UI
import { withAllServices } from '@invitation-homes/styled-ui/lib/providers/Services';
import { withEmitter } from '@invitation-homes/styled-ui/lib/providers/Emitter';
import CircleLoader from '@invitation-homes/styled-ui/lib/components/Loader/CircleLoader';

import url from 'url';
import styled from 'styled-components';
import { Redirect, Route } from 'react-router-dom';
import axios from 'axios';
import { trackGTMEvent } from '../utils/gtm';

import DayTimePicker from '../containers/DayTimePicker';

import ErrorMessage from '../components/ErrorMessage';
import TimeoutDialog from '../components/TimeoutDialog';
import TimeoutWarningDialog from '../components/TimeoutWarningDialog';
import SelfTourHeader from '../components/SelfTourHeader';

const WhiteGrid = styled(Grid)`
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 40px;
  padding-left: 20px;
  background-color: white;
`;

export class ScheduleShowing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: new Date(),
      timeout: Date.now() + 1000 * 60 * 20,
      warning: Date.now() + 1000 * 60 * 15,
      warningInterval: null,
      timeoutInterval: null,
      warningDialogOpen: false,
      timeoutDialogOpen: false,
      errorMessage: null,
      loading: false,
      daySelected: null,
      timeSelected: null,
    };
  }

  handleDateTimeData = (field, value) => {
    this.setState((prevState) => {
      if (field === 'daySelected' && !!prevState['timeSelected']) {
        prevState[field] = assign({}, value);
        prevState.timeSelected = null;
        return prevState;
      }
      prevState[field] = assign({}, value);
      return prevState;
    });
  };

  handleStepError = (errorMessage) => {
    this.setState({ errorMessage });
  };

  createInterval = (when, cb) => {
    if (!when) {
      throw new Error('When must be a valid date');
    }

    const interval = setInterval(() => {
      if (Date.now() > when) {
        cb?.();
        clearInterval(interval);
      }
    }, 1000);

    return interval;
  };

  componentDidMount() {
    if (window && window.location) {
      const getParsedUrl = () => {
        return url.parse(window.location.href, true);
      };
      const error = getParsedUrl().query && getParsedUrl().query.err;
      if (error) {
        this.setState({ errorMessage: error });
      }
    }

    this.setState({
      warningInterval: this.createInterval(this.state.warning, () => this.setState({ warningDialogOpen: true })),
      timeoutInterval: this.createInterval(this.state.timeout, () => this.setState({ timeoutDialogOpen: true })),
    });
  }

  createShowing = async () => {
    const {
      daySelected,
      timeSelected: { start, end },
    } = this.state;
    const {
      history,
      match: {
        params: { unit_id },
      },
    } = this.props;

    await axios
      .post('/api/v1/showing', {
        unit_id,
        date: moment(daySelected),
        start_time: start,
        end_time: end,
      })
      .then(({ data: { id, error = false } }) => {
        if (error) {
          this.setState({ errorMessage: error });
          return;
        }

        localStorage.removeItem('unit_id');
        trackGTMEvent({
          application: 'self-show',
          event: 'SS_Create_Showing',
        });
        history.push(`/home/${id}`);
      })
      .catch(({ response }) => {
        const errorMessage = response?.data?.errors?.message;
        this.setState({
          errorMessage: errorMessage ? errorMessage : 'ERR_SHOWINGS_MULTIPLE_STATES',
        });
      });
  };

  renderAuthentication({ market }) {
    clearInterval(this.state.warningInterval);
    clearInterval(this.state.timeoutInterval);

    return (
      <Fragment>
        {
          !market.fraud_verification_mode && <h3>disabled</h3>
          // TODO: disabled fraud verification mode null
        }
      </Fragment>
    );
  }

  onWarningTimeoutDialogClose = () => {
    this.setState({ warningDialogOpen: false });
  };

  onTimeoutDialogClose = () => {
    this.setState({ timeoutDialogOpen: false, warningDialogOpen: false });
    window.location.reload();
  };

  render() {
    const { errorMessage, daySelected, timeSelected, loading, start, timeout, warningDialogOpen, timeoutDialogOpen } =
      this.state;
    const {
      match,
      unitAddress,
      market,
      location,
      services: { auth },
    } = this.props;

    const invalidRoute =
      !location.pathname.includes('/case') && (!daySelected || !timeSelected) && location.pathname !== match.url;

    if (errorMessage) {
      return (
        <ErrorMessage padding={{ pt: 32, pb: 48, pl: 0, pr: 0 }} type={errorMessage} unitId={this.props.match.params} />
      );
    }

    if (loading) {
      return <CircleLoader size={24} />;
    }

    return (
      <Grid container xs={12} direction="row" alignItems="flex-start" justify="center">
        <Grid item xs={12} lg={10}>
          <SelfTourHeader />
        </Grid>
        <Route
          path={match.path}
          exact
          render={() => {
            return (
              <WhiteGrid item xs={12} lg={10} bg="white">
                <DayTimePicker
                  onChange={this.handleDateTimeData}
                  unitAddress={unitAddress}
                  daySelected={daySelected}
                  timeSelected={timeSelected}
                  location={location}
                  match={match}
                  createShowing={this.createShowing}
                  user={auth._user}
                />
              </WhiteGrid>
            );
          }}
        />
        {invalidRoute ? (
          <Redirect to={{ pathname: match.url }} />
        ) : (
          <WhiteGrid item xs={12} lg={10} bg="white">
            <Route
              path={[`${match.url}/case/:id`, `${match.url}/authentication`]}
              render={() => this.renderAuthentication({ market })}
            />
          </WhiteGrid>
        )}
        <TimeoutWarningDialog
          open={warningDialogOpen}
          start={start}
          timeout={timeout}
          onClose={this.onWarningTimeoutDialogClose}
        />
        <TimeoutDialog open={timeoutDialogOpen} onClose={this.onTimeoutDialogClose} />
      </Grid>
    );
  }
}
export default withAllServices(withEmitter(ScheduleShowing));
