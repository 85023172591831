import React from 'react';
import styled from 'styled-components';
// Material UI
import Grid from '@material-ui/core/Grid';
import withTheme from '@material-ui/styles/withTheme';

export const Label = styled.div`
  font-size: 1rem;
  margin: ${(props) => props.margin || `0px;`};
  -webkit-font-smoothing: antialiased;
  user-select: none;
  font-weight: lighter;
  color: ${({ color = '#00000085' }) => color};

  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

export const Value = styled.div`
  ${(props) => (props.width ? `width: ${props.width}` : '')}
  ${(props) => (props.padding ? `padding: ${props.padding}` : '')}
  ${(props) =>
    props.centered
      ? `display: flex;
  flex-direction: column;
  align-items: center;`
      : ''}
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.2rem')};
  margin: ${(props) => props.margin || `0 0 15px 0`};
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  color: #000000c9;

  @media (max-width: 1250px) {
    font-size: 1rem;
  }

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }

  @media (max-width: 960px) {
    width: unset;
    margin: ${(props) => props.margin || `8px 0`};
  }
`;

export const Info = styled.div`
  font-size: 16px;
  color: #808080;
  -webkit-font-smoothing: antialiased;
  margin-top: 8px;

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;

export const StyledGrid = styled(Grid)`
  box-sizing: border-box;
  margin: 0;
  flex-grow: 0;
  max-width: 20%;
  flex-basis: 20%;
  margin: 10px;
  background-color: white;

  @media (max-width: 730px) {
    max-width: 45%;
    flex-basis: 45%;
  }
  @media (max-width: 570px) {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const Disabled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: opacity 175ms ease-in-out;
  opacity: 1;
  cursor: not-allowed;

  span {
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
  }
`;
Disabled.displayName = 'Disabled';

export const Heading = withTheme(styled.h3`
  width: 80%;
  padding: 0;
  font-size: ${(props) => props.theme.typography.pxToRem(19)};
  color: ${(props) => props.theme.palette.ih.silver_chalice};
  -webkit-font-smoothing: antialiased;
  color: inherit;
  font-weight: normal;

  @media (max-width: 980px) {
    font-size: ${(props) => props.theme.typography.pxToRem(17)};
  }
`);

export const PageHeading = withTheme(styled(Heading)`
  font-size: ${(props) => props.theme.typography.pxToRem(25)};

  @media (max-width: 1500px) {
    font-size: ${(props) => props.theme.typography.pxToRem(20)};
  }
`);

export const PageSubHeading = withTheme(styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
`);

export const MessageContainer = withTheme(styled.div`
  background-color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`);

export const MessageHeader = styled.h2`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  color: var(--color-secondary-dark);
  margin-bottom: 12px;
`;

export const MessageDescription = styled.div`
  font-weight: lighter;
  font-size: 16px;
  max-width: 510px;
  text-align: center;
  color: var(--color-secondary-dark);
`;
