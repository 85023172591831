import authClient from './auth-client';
import { makeUseAxios } from 'axios-hooks';
import ShowingsService from './showings';
import emitter from './emitter';
import axios from 'axios';

// Storage service
import storage from './storage';
storage.verifyVersion();

// Showings service
const showings = new ShowingsService();

// Auth service
export const auth = authClient;

const useAxios = makeUseAxios({ axios });
const useAuthAxios = makeUseAxios({ axios: auth.connector });

module.exports = {
  services: {
    auth,
    storage,
    showings,
  },
  emitter,
  useAxios,
  useAuthAxios,
};
