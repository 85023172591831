import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Days from './Days';
import Times from './Times';

import { useHttp } from '@invitation-homes/styled-ui/lib/hooks/use-http';
import CircleLoader from '@invitation-homes/styled-ui/lib/components/Loader/CircleLoader';
import FormSection from '@invitation-homes/styled-ui/lib/components/Form/Section';
import Grid from '@material-ui/core/Grid';

import Box from '@material-ui/core/Box';

import { PageSubHeading, Heading, PageHeading } from '../components/CardCommon';
import ErrorMessage from '../components/ErrorMessage';
import ShowingNav from '../components/ShowingNav';

function DayTimePicker({ unitAddress, daySelected, timeSelected, location, match, onChange, createShowing, user }) {
  const { unit_id } = match.params;
  const isTrusted =
    user.trusted && moment(user.trusted_at).add(process.env.REACT_APP_TRUSTED_VALIDITY_DAYS || 30, 'days') > moment();

  const { data, error, loading } = useHttp({
    endpoint: `/api/v1/availability?unit_id=${unit_id}`,
  });

  if (loading) {
    return (
      <Grid container justify="center" spacing={0}>
        <Grid item xs={12} sm={10} md={8}>
          <Box py={20}>
            <CircleLoader size={20} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justify="center" spacing={0}>
      <Grid item xs={12}>
        {!error && unitAddress && (
          <PageHeading>
            Schedule Self-Tour <PageSubHeading>for {unitAddress}</PageSubHeading>
          </PageHeading>
        )}
        <FormSection>
          {error ? (
            <ErrorMessage type={data.error} unitId={match.params} />
          ) : (
            <div role="radiogroup" aria-labelledby="days_heading">
              <Heading id="days_heading">Select A Day</Heading>
              <Days
                initialNumShown={4}
                onSelect={(day) => {
                  onChange('daySelected', day);
                }}
                selected={daySelected}
                available={data.available}
                timezone={data.unitTimezone}
              />
            </div>
          )}
        </FormSection>
        {daySelected && (
          <FormSection>
            <Heading>Select A Time</Heading>
            <Times
              onSelect={(time) => {
                onChange('timeSelected', time);
              }}
              selectedTime={timeSelected}
              selectedDay={daySelected}
              available={data.available}
              timezone={data.unitTimezone}
            />
          </FormSection>
        )}
        {!error && (
          <ShowingNav
            next
            handleNextClick={isTrusted && createShowing}
            nextDisabled={!daySelected || !timeSelected}
            nextTo={
              !isTrusted && {
                pathname: `${match.url}/authentication`,
                search: location.search,
              }
            }
          />
        )}
      </Grid>
    </Grid>
  );
}

export default withRouter(DayTimePicker);
