import React, { Fragment } from 'react';

import withTheme from '@material-ui/styles/withTheme';

import styled from 'styled-components';
import { contactUsLink, updateProfileLink } from './profileLinks';
import { MessageContainer, MessageDescription } from '../components/CardCommon';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const TextLink = withTheme(styled.a`
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: bold;
  text-decoration: unset;
`);

const ContactUsLink = withTheme(styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
  margin-left: 4px;
`);

export default {
  ERR_SHOWINGS_MULTIPLE_STATES: {
    title: 'Unable to schedule your Self-tour',
    message: (
      <Fragment>
        We were unable to verify the information provided. To schedule an in-person tour for this property, please
        <ContactUsLink href={contactUsLink()}>contact us</ContactUsLink>.
      </Fragment>
    ),
  },
  ERR_USER_NOT_ACTIVE_AND_VERIFIED: {
    title: 'Finish Setting Up Your Account',
    message: (
      <Fragment>
        Your account is not active yet. Please verify your account’s email address and phone number to continue. If you
        did not get a verification email, please{' '}
        <TextLink href={`${process.env.REACT_APP_CUSTOMER_WEBSITE_URL}/profile/forgot-password`}>reset</TextLink> your
        account password to verify your account.
      </Fragment>
    ),
    showForm: false,
  },
  ERR_SUSPICIOUS_USER: {
    title: 'Oops, it seems we’re having an issue on our side.',
    message: <Fragment>Please contact us to get access to this property.</Fragment>,
    showForm: true,
  },
  ERR_TOUR_GENERIC: {
    title: 'Oops! Something went wrong',
    message: (
      <Fragment>
        <MessageDescription>The Self-Tour information you're looking for is stuck momentarily.</MessageDescription>
        <MessageDescription>
          You could <TextLink href="/">Try Again</TextLink> or head back to your{' '}
          <TextLink href={updateProfileLink()}>Profile</TextLink>.
        </MessageDescription>
        <Box mt={2}>
          <Button color="primary" variant="contained" href={process.env.REACT_APP_PLS_URL} fontSize="10px">
            Search Our Homes
          </Button>
        </Box>
      </Fragment>
    ),
    showForm: false,
  },
  ERR_UNIT_NOT_AVAILABLE: {
    title: 'This Home is Unavailable - Find Another Today!',
    message: (
      <Fragment>
        This home is no longer available for lease. Please visit{' '}
        <TextLink href="https://invitationhomes.com">Invitation Homes</TextLink> to find another one of our high-quality
        homes that is right for you.
      </Fragment>
    ),
    showForm: false,
  },
  // Catch all
  _: {
    title: 'Unknown System Error',
    message: (
      <Fragment>
        An unknown error occurred. Please <TextLink href={process.env.REACT_APP_BASE_URL}>refresh</TextLink> your
        browser window. If the problem continues, please contact our customer care team at (888) 990-4684 to be
        connected with a leasing professional who can schedule an in-person tour.
      </Fragment>
    ),
    showForm: false,
  },
};
